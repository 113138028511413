<script setup lang="ts">
import { getArticleDetail } from '~/api'
import type { ReturnPromiseType } from '~/types/base'

definePageMeta({
  validate: () => {
    return !isProd()
  },
})

const route = useRoute()

const { data, error } = await useAsyncData(`blog-${route.params.seo}`, () => getArticleDetail(route.params.seo as string), {
  getCachedData: (key, nuxt): ReturnPromiseType<typeof getArticleDetail> => nuxt.payload.data[key] || nuxt.static.data[key],
})

whenever(error, () => {
  useRouteCache(route => route.setUncacheable())
  throw createError({ statusCode: 404, statusMessage: `blog-${route.params.seo} has some error`, data: { params: route.params }, fatal: true })
}, { immediate: true })
</script>

<template>
  <div v-track="{ id: 'web-blogs', view: { value: { page: 'blogs-seo', ...$route.params } } }">
    {{ $route.params.seo }}--{{ data }}
  </div>
</template>

<style lang="scss" scoped>
</style>
